<template>
  <div id="TopNav" class="sticky-top row" :style="{ backgroundColor: bgColor }">
    <div class="logo">
      <img :src="logoSrc" alt="" />
    </div>
    <div :class="{ isFixedTop: isFixedTop }" class="linkList hiden">
      <router-link
        v-for="item in navList"
        :key="item.path"
        :href="item.tolink ? item.tolink : '#'"
        :to="{ path: item.path }"
        :target="item.tolink ? '_blank' : ''"
        >{{ item.name }}</router-link
      >
    </div>
    <div class="show listIcon" @click="onSelect">
      <img src="../assets/imgs/list_icon.png" alt="" />
    </div>
    <van-action-sheet
      v-model:show="showNavList"
      @select="onSelect"
      cancel-text="取消"
      close-on-click-action
      class="routerList"
      duration="0.2"
    >
      <div class="content" v-for="(item, index) in navList" :key="index">
        <dl
          v-if="item.downloadList"
          class="downloadApp"
          @click="optionsCli(item)"
        >
          <dt>{{ item.name }}</dt>
          <dd
            v-for="(val, ind) in item.downloadList"
            :key="ind"
            @click.stop="downloadApp(val)"
          >
            {{ val.name }}
          </dd>
        </dl>
        <dl v-else class="intoPath" @click="optionsCli(item)">
          <dt>{{ item.name }}</dt>
          <dd></dd>
        </dl>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "TopNav",
  setup(props) {
    const thisRoute = null;
    const bgColor = ref("#5679F9");
    const isPhone = ref(false);
    const isFixedTop = ref(false);
    const logoSrc = ref(require("../assets/imgs/top_logo.png"));
    const showNavList = ref(false);
    const router = useRouter();
    const navList = [
      {
        name: "首页",
        path: "/",
      },
      {
        name: "产品",
        path: "/product",
      },
      {
        name: "官方文档",
        tolink: "https://www.w-o-l-b.com/docs",
        path: "https://www.w-o-l-b.com/docs",
      },
      {
        name: "下载",
        path: "/downloadApp",
        downloadList: [
          {
            name: "iOS版",
            url: "https://apps.apple.com/us/app/id1562940514",
          },
          {
            name: "Android版",
            url: "https://file.w-o-l-b.com/wolb.apk",
          },
        ],
      },
      {
        name: "关于我们",
        path: "/aboutUs",
      },
      // {
      //   name: "精选文章",
      //   path: "/choiceness",
      // },
    ];
    const onSelect = (item) => {
      showNavList.value = !showNavList.value;
    };
    const optionsCli = (item) => {
      showNavList.value = !showNavList.value;
      if(item.tolink){
        window.location.href = item.tolink
      }else{
        router.push({ path: item.path });
      }
    };
    const downloadApp = (item) => {
      showNavList.value = !showNavList.value;
      window.location.href = item.url;
    };
    return {
      bgColor,
      isFixedTop,
      navList,
      thisRoute,
      isPhone,
      logoSrc,
      onSelect,
      showNavList,
      optionsCli,
      downloadApp,
    };
  },
  methods: {},
  mounted() {
    if (document.documentElement.clientWidth > 600) {
      $(".main").on("scroll", () => {
        if (this.thisRoute === "/") {
          let topHeight = $("#TopNav").height() / 3;
          if ($(".main").scrollTop() > topHeight) {
            this.bgColor = "#fff";
            this.isFixedTop = true;
          } else {
            this.bgColor = "#5679F9";
            this.isFixedTop = false;
          }
        }
      });
    } else {
      this.isPhone = true;
      this.isFixedTop = true;
    }
  },
  watch: {
    $route(to) {
      this.thisRoute = to.path;
      if (to.path === "/") {
        this.bgColor = "#5679F9";
        this.isFixedTop = false;
      } else {
        this.bgColor = "#fff";
        this.isFixedTop = true;
      }
    },
    isFixedTop(type) {
      if (this.isPhone) {
        this.bgColor = "#fff";
        this.logoSrc = require("../assets/imgs/top_logo_phone.png");
      } else {
        if (type) {
          this.logoSrc = require("../assets/imgs/top_logo_text.png");
        } else {
          this.logoSrc = require("../assets/imgs/top_logo.png");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#TopNav {
  width: 100%;
  display: flex;
  align-items: center;
  height: 110px;
  padding-left: 55px;
  padding-right: 127.5px;
  margin: 0;
  transition: all 0.3s linear;
  z-index: 2222;
  .logo {
    width: 150px;
  }
  img {
    width: 114px;
  }
  .linkList {
    text-align: right;
    flex: 1;
    a {
      font-size: 20px;
      color: #fff;
      padding: 0 35px;
      line-height: 110px;
      display: inline-block;
    }
    a.router-link-active:not(:first-child) {
      color: #165dee;
    }
  }
  .isFixedTop a {
    color: #333;
  }
}
.logo {
  line-height: 110px;
  margin-left: 100px;
}
@media screen and (max-width: 600px) {
  #TopNav {
    padding: 0 31px;
    justify-content: space-between;
    .logo {
      width: 266px;
      margin-left: 0px;
      img {
        width: 266px;
      }
    }
    .listIcon {
      width: auto;
      img {
        width: 52px;
      }
    }
  }
  ::v-deep .routerList {
    padding-left: 0;
    padding-right: 0;
    border-radius: 48px 48px 0px 0px;
    .content:not(:last-of-type) {
      border-bottom: 1px solid #eeeeee;
    }
    .content {
      margin-left: 60px;
      margin-right: 70px;
    }
    dl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 128px;
      margin-bottom: 0;
      dt {
        font-size: 34px;
        font-weight: 500;
        flex: 1;
      }
    }
    .intoPath {
      dd {
        &::after {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          border-right: 2px solid #999999;
          border-bottom: 2px solid #999999;
          transform: rotate(-45deg) translateY(5.5px) translateX(-5.5px);
        }
      }
    }
    .downloadApp {
      margin-bottom: 0;
      dd {
        background-color: #ecf0ff;
        margin-left: 25px;
        color: #4d6fe3;
        border-radius: 12px;
        font-size: 32px;
      }
      dd:first-of-type {
        padding: 5px 32px;
      }
      dd:last-of-type {
        padding: 5px 27px;
      }
    }
  }
  ::v-deep .van-action-sheet__gap {
    background-color: #f9faff;
    height: 24px;
  }
  ::v-deep .van-action-sheet__cancel {
    color: #333333;
    padding: 46px 0 57px;
    font-size: 34px;
    font-weight: 500;
  }
}
</style>
